import React from "react";

const AboutPage = () => (
  <div>
    <h1>About Us</h1>
    <p>
      This blog is a personal project to share knowledge and experiences on
      various subjects.
    </p>
  </div>
);

export default AboutPage;
